import React, { useState } from "react";
import Layout from "../Layout";
import "./style.scss";
import nityaBalagopalImage from "../../images/nitya-balagopal-photo-removebg-preview.png";
import govindSharmaImage from "../../images/govind-photo.png";
import rahulImage from "../../images/rahul.png";
import himanshuImage from "../../images/himanshu.png";
import hemantImage from "../../images/hemant.png";
import durgeshImage from "../../images/Durgesh.png";
import ankurImage from "../../images/ankur.png";
import mukeshImage from "../../images/mukesh.png";
import _ from "lodash";

export default function TeamPage() {
  const nityaBio = {
    name: "Nitya Balagopal",
    designation: "Founder",
    linkedin: "https://www.linkedin.com/in/nityab/",
    body: `Nitya is a seasoned marketing strategist, leading a full spectrum of marketing services for renowned brands across industries. Her expertise lies in building sustainable digital marketing solutions and she prides herself on delivering this through a very transparent, hands-on approach. Black Elephant Digital completes the 360 degree media solutions bouquet with the renowned 30-year old INS accredited agency, Copy Desk Creative Services, that is her family’s legacy.<br/><br/>
 
    Nitya graduated summa cum laude, on the Dean’s List for Academic Excellence, from Imperial College London, with a Master of Science in Strategic Marketing. She also has a Master of Arts in Economics from Adam Smith Business School, University of Glasgow.<br/><br/>

    She has previously worked at Catalyst, a financial inclusion and digital payment solutions project, housed at IFMR Lead, India, with the Artificial Intelligence-powered SAAS platform VMock, and also with The Economic Times.`,
    image: nityaBalagopalImage,
    backgroundColor: "#a267ac",
  };

  const himanshuBio = {
    name: "Himanshu Varshney",
    designation: "Web Application Developer",
    body: `An experienced React and JavaScript Developer, Himanshu started coding. when he was only 17. He is especially adept at problem-solving and logic building- and downright impatient with inefficient code and structures. Quick on the uptake, he is a natural trouble-shooter and ‘early adopter’- effortlessly learning new technologies to deliver digital brand experiences.<br/><br/>
 
    Deeply passionate about gaming, Himanshu has published an online multiplayer game ‘Turup Chaal’ on the Google Play Store- with over 10000+ downloads. He rarely shuts down his beloved PC and devotes his leisure hours to God of War or Witcher 3.`,
    image: himanshuImage,
    backgroundColor: "#F6BA79",
  };

  const govindBio = {
    name: "Govind Sharma",
    designation: "Sr. Graphic Designer",
    body: `Govind is an experienced commercial design professional with over ten years of experience across print production and digital platforms. He can be counted on to carefully assess the scope of each project undertaken with a pragmatic eye- delivering the best with a laser focus on customer centricity. Govind creates beautiful print works as well as web screens with thoughtful touches borne out of experience.<br/><br/>
 
    He has previously headed print production at The Brand Bee, Digital World and the Print Bazaar, and spearheaded the branding of the Coke Museum in Punjab. He’s still prouder of having been cricket captain in school, though!`,
    image: govindSharmaImage,
    backgroundColor: "#F6D860",
  };

  const rahulBio = {
    name: "Rahul Kumar",
    designation: "Illustrator & Designer",
    body: `A creative and cheerful soul, Rahul’s expertise is well-demonstrated across mediums. Highly skilled in Artwork, Illustrator, Photoshop, he is responsible for fleshing out creative concepts for social media in real time. He is equally adept at sincerely creating illustrations from scratch (and has prior agency experience in this) as he is in patiently going the extra mile to perfect the small details of brand identity. <br/><br/>
 
    The team is certain that Rahul has churned out over a 100 Instagram reels and stories since he makes several a day. He has also written the screenplay and acted in the short movie- Panney, which won the 24FPS Award!`,
    image: rahulImage,
    backgroundColor: "#5BD1C7",
  };

  const hemantBio = {
    name: "Hemant Pundeer",
    designation: "Studio Engineer",
    body: `With over 19 years of experience in sound recording, Hemant is responsible for the smooth rendering of documentaries, radio spots, jingles, IVRs and voice overs at Black Elephant Digital Studios. Through the course of his career, he has worked with several production houses including Pindrop Studios, Bala Communications and ESPN amongst others.
    <br/><br/>
    He patiently handles all the studio work- guiding newcomers and stalwarts alike for the perfect audio output for marketing. From award-winning tableaux music to private album recording, Hemant has done it all in the AV field.`,
    image: hemantImage,
    backgroundColor: "#42AEEF",
  };

  // const ankurBio = {
  //   name: "Ankur Arya",
  //   designation: "Lead Management Specialist",
  //   body: `Ankur is responsible for validating funnel setup and qualified leads across brand platforms. This streamlines targeting for brand ads, as well as paves the way for brand sales processes. His prior experience includes working with Provana India and projects with Whitehat Jr., Uber, Future Group, Byju’s, Swiggy, American Express, Barclays Bank, GMR Group, Accenture, HCL and MGRM amongst others.<br/><br/>

  //   As an MBA with a specialization in HR, he is well-versed in all aspects of corporate HR, L&D, talent management and workforce technology.`,
  //   image: ankurImage,
  //   backgroundColor: "#5ED7C4",
  // };

  const durgeshBio = {
    name: "Durgesh Kumar Sable",
    designation: "Web App Developer",
    body: `Durgesh started his career at Black Elephant Digital, fresh out of NIT Kurukshetra with a Master’s degree in Computer Applications. He provided much needed support in frontend web development and is a conscientious worker, cheerfully batting out endless web pages with elan. Durgesh developed and maintained BE projects including the websites for WinPE Development Forum, Jaycee Punching Solutions and Chinmaya Vidyalaya, Vasant Vihar, amongst others.<br/><br/>
 
    Even as he moved on to the next step of his career at Successive Technologies, Durgesh continues to be a part of the Black Elephant family in spirit and enjoys team-wide popularity for his sincerity and good-natured attitude.`,
    image: durgeshImage,
    backgroundColor: "#ED4F51",
  };

  const mukeshBio = {
    name: "Mukesh Kumar",
    designation: "Sr. Graphic Designer",
    body: `Mukesh was a valuable member of the design team, always cheerful and ready to lend a helping hand. With over 17 years of graphic design experience, he approached each brief with a keen eye for detail and a steadfastly mature and professional approach. Mukesh’s prior experience included long tenures with Techbook (Aptara) International, Pikperfect, Solution Dot Com and Express Knowledge Communication. <br/><br/>
 
    His honesty and humour made him very well-liked across functions and teams. His sudden passing during the pandemic shocked the organization, and he is deeply missed and fondly remembered by one and all.`,
    image: mukeshImage,
    backgroundColor: "#F8D354",
  };

  const [popupData, setPopupData] = useState({});

  return (
    <Layout>
      <div className="team">
        <div
          className={`team__popup ${
            !_.isEmpty(popupData) && "team__popup--active"
          }`}
        >
          <div className="team__popup__content">
            <button onClick={() => setPopupData({})}>
              <i className="fa fa-times"></i>
            </button>
            <div className="team__popup__content__left">
              <div
                className="team__popup__image"
                style={{ backgroundColor: popupData.backgroundColor }}
              >
                <img src={popupData.image}></img>
              </div>
              <div className="team__popup__name">{popupData.name}</div>
              <div className="team__popup__designation">
                {popupData.designation}
              </div>
              {popupData.linkedin && (
                <a
                  className="team__popup__linkedin"
                  href={popupData.linkedin}
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              )}
            </div>
            <div className="team__popup__content__right">
              <p dangerouslySetInnerHTML={{ __html: popupData.body }}></p>
            </div>
          </div>
        </div>
        <div className="team__container">
          <h1>Meet The Team</h1>
          <p>
            At Black Elephant, we firmly believe that marketing is teamwork.
            After all, for a campaign to gain mass popularity, it needs to be a
            product of diverse perspectives and viewpoints. Our marketing
            outputs are presented only when they achieve the hard-won approval
            of our discerning team. We work like a family, and it shows in the
            consistent quality and cohesion of digital journeys we build.
          </p>
          <div className="team__content">
            <div className="team__content__cards">
              <div
                className="team__card"
                onClick={() => {
                  setPopupData(nityaBio);
                }}
              >
                <div className="team__card__background"></div>
                <div className="team__card__background2"></div>
                <div className="team__card__silhouette"></div>
                <img
                  className="team__card__pic"
                  src={nityaBalagopalImage}
                ></img>

                <div className="team__card__name">nitya</div>
                <div className="team__card__designation">founder</div>
              </div>

              <div
                className="team__card"
                onClick={() => {
                  setPopupData(rahulBio);
                }}
              >
                <div className="team__card__background"></div>
                <div className="team__card__background2"></div>
                <div className="team__card__silhouette"></div>
                <img className="team__card__pic" src={rahulImage}></img>

                <div className="team__card__name">rahul</div>
                <div className="team__card__designation">
                  illustrator & designer
                </div>
              </div>

              <div
                className="team__card"
                onClick={() => {
                  setPopupData(himanshuBio);
                }}
              >
                <div className="team__card__background"></div>
                <div className="team__card__background2"></div>
                <div className="team__card__silhouette"></div>
                <img className="team__card__pic" src={himanshuImage}></img>

                <div className="team__card__name">himanshu</div>
                <div className="team__card__designation">web developer</div>
              </div>

              <div
                className="team__card"
                onClick={() => {
                  setPopupData(hemantBio);
                }}
              >
                <div className="team__card__background"></div>
                <div className="team__card__background2"></div>
                <div className="team__card__silhouette"></div>
                <img className="team__card__pic" src={hemantImage}></img>

                <div className="team__card__name">hemant</div>
                <div className="team__card__designation">studio engineer</div>
              </div>

              {/* <div
                className="team__card"
                onClick={() => {
                  setPopupData(ankurBio);
                }}
              >
                <div className="team__card__background"></div>
                <div className="team__card__background2"></div>
                <div className="team__card__silhouette"></div>
                <img className="team__card__pic" src={ankurImage}></img>
                <div className="team__card__name">ankur</div>
                <div className="team__card__designation">
                  lead management specialist
                </div>
              </div> */}
            </div>

            <br />
            <br />
            <h2>Our Alumni</h2>
            <br />
            <div className="team__content__alumnicards">
              <div
                className="team__alumnicard"
                onClick={() => {
                  setPopupData(durgeshBio);
                }}
              >
                <div className="team__alumnicard__background"></div>
                <div className="team__alumnicard__background2"></div>
                <div className="team__alumnicard__silhouette"></div>
                <img className="team__alumnicard__pic" src={durgeshImage}></img>

                <div className="team__alumnicard__name">durgesh</div>
                <div className="team__alumnicard__designation">
                  frontend developer
                </div>
              </div>

              <div
                className="team__alumnicard"
                onClick={() => {
                  setPopupData(mukeshBio);
                }}
              >
                <div className="team__alumnicard__background"></div>
                <div className="team__alumnicard__background2"></div>
                <div className="team__alumnicard__silhouette"></div>
                <img className="team__alumnicard__pic" src={mukeshImage}></img>

                <div className="team__alumnicard__name">mukesh kumar</div>
                <div className="team__alumnicard__designation">
                  Sr. Graphic Designer
                </div>
              </div>

              <div
                className="team__alumnicard"
                onClick={() => {
                  setPopupData(govindBio);
                }}
              >
                <div className="team__card__background"></div>
                <div className="team__card__background2"></div>
                <div className="team__card__silhouette"></div>
                <img className="team__card__pic" src={govindSharmaImage}></img>

                <div className="team__card__name">govind</div>
                <div className="team__card__designation">
                  sr. graphic designer
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
